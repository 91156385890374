<template>
  <div
    @dragstart="dragStart($event)"
    @dragend="dragEnd($event)"
    v-if="session"
    :draggable="draggable"
    :class="{
      dragged: isDragged,
      'border-primary': sessionIsSelected,
    }"
    class="card bg-white user-select-none"
  >
    <!-- HEADER -->
    <div
      @click="editSession"
      class="session-header-container d-flex align-items-center position-relative px-2 py-3"
    >
      <div @click.stop class="form-check">
        <input
          @click="selectSession($event)"
          :checked="sessionIsSelected"
          :id="`cb-${sessionId}`"
          ref="checkbox"
          type="checkbox"
          class="form-check-input"
        >
        <label :for="`cb-${sessionId}`" class="form-check-label"></label>
      </div>

      <div class="session-header flex-grow-1 lh-sm small text-break fw-bold" role="button">
        <span v-if="session.name" class="">{{ session.name }}</span>
        <span v-else class="text-muted fw-normal fst-italic">(No name)</span>
      </div>

      <fa-icon
        @click.stop
        @mousedown.stop="setDraggable(true)"
        @mouseup.stop="setDraggable(false)"
        :icon="['fas', 'arrows-alt']"
        :class="dragIconClass"
        class="ms-2"
      ></fa-icon>
    </div>

    <!-- EXERCISES -->
    <div v-if="session.exercises.length" class="small border-top p-2">
      <div
        v-for="(exercise, exerciseIndex) in session.exercises"
        :key="`key-${exerciseIndex}-${exercise.exerciseId}`"
        class="d-flex align-baseline"
      >
        <ExerciseBadge
          v-if="exercise.type !== 'section'"
          :exercise="exercise"
          :exercises="session.exercises"
          :variant="'text'"
          class="me-1"
        ></ExerciseBadge>

        <div class="flex-grow-1">
          <div :class="exercise.type === 'section' ? 'fw-bold' : ''">
            <span
              @click="showExerciseDescription(exercise)"
              v-if="isCompact && exercise.description && exercise.description !== ''"
              role="button"
            >
              <span class="me-1">{{ exercise.name }}</span>
              <fa-icon :icon="['fas', 'info-circle']" class="me-1 text-muted"></fa-icon>
            </span>
            <span v-else class="me-1">{{ exercise.name }}</span>
          </div>

          <div
            v-if="!isCompact && exercise.description && exercise.description !== ''"
            v-html="$options.filters.nl2br(exercise.description)"
            class="smaller text-muted"
          ></div>

          <ExerciseSetRep
            v-if="!isCompact"
            :sets="$options.filters.exerciseSetParsed(exercise)"
          ></ExerciseSetRep>
        </div>
      </div>
    </div>

    <portal to="modal">
      <transition name="modal">
        <ExerciseDescriptionModal
          @close="hideExerciseDescription"
          v-model="showExerciseDescriptionModal"
          v-if="showExerciseDescriptionModal && activeExercise !== null"
          :exercise="activeExercise"
        ></ExerciseDescriptionModal>
      </transition>
    </portal>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'ProgramSessionItem',
  components: {
    ExerciseBadge: () => import('@/components/ExerciseBadge'),
    ExerciseSetRep: () => import('@/components/ExerciseSetRep'),
    ExerciseDescriptionModal: () => import('@/components/modal/ExerciseDescriptionModal'),
  },
  props: {
    sessionId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState('config', ['configUICalendarViewMode']),
    ...mapGetters('program', ['programGetSession', 'programGetSessionIsSelected', 'programGetSelectedSessions']),
    session() {
      return this.programGetSession(this.sessionId);
    },
    sessionIsSelected() {
      return this.programGetSessionIsSelected(this.sessionId);
    },
    isCompact() {
      return this.configUICalendarViewMode === 'compact';
    },
    isFirstInSelectedSessions() {
      const { sessionId } = this;
      const index = this.programGetSelectedSessions.findIndex((s) => s.sessionId === sessionId);
      return index === 0;
    },
    dragIconClass() {
      const { programGetSelectedSessions, isFirstInSelectedSessions } = this;
      if (!programGetSelectedSessions.length || isFirstInSelectedSessions) return '';
      return 'invisible no-interaction';
    },
  },
  watch: {
    sessionIsSelected() {
      this.$refs.checkbox.blur();
    },
  },
  methods: {
    setDraggable(bool) {
      this.draggable = bool;
    },
    dragStart(e) {
      const { sessionId, date } = this.session;
      e.dataTransfer.setData('sessionId', sessionId);
      e.dataTransfer.setData('sessionDate', date);
      this.isDragged = true;
    },
    dragEnd() {
      this.isDragged = false;
    },
    editSession() {
      this.$store.dispatch('program/setSessionInEditing', this.sessionId);
    },
    selectSession(e) {
      const { sessionId, sessionIsSelected } = this;
      if (sessionIsSelected) {
        this.$store.dispatch('program/deleteSelection', { sessionId });
      } else {
        this.$store.dispatch('program/addSelection', {
          sessionId,
          isMultiple: e.shiftKey,
        });
      }
    },
    showExerciseDescription(exercise) {
      this.activeExercise = exercise;
      this.showExerciseDescriptionModal = true;
    },
    hideExerciseDescription() {
      this.showExerciseDescriptionModal = false;
      this.activeExercise = null;
    },
  },
  data() {
    return {
      draggable: false,
      isDragged: false,
      showExerciseDescriptionModal: false,
      activeExercise: null,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars.scss';

.session-header-container {
  border-radius: calc(#{$borderRadius} - 1px) calc(#{$borderRadius} - 1px) 0 0;
  background-color: transparent;
  padding-bottom: .5rem !important;
}

.form-check {
  position: absolute;
  left: .5rem;
  top: -1rem;
  margin: 0;

  > .form-check-input {
    width: 1.5em;
    height: 1.5em;
  }
}
</style>
